// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordinace-js": () => import("/opt/build/repo/src/pages/ordinace.js" /* webpackChunkName: "component---src-pages-ordinace-js" */),
  "component---src-pages-profil-js": () => import("/opt/build/repo/src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-sluzby-js": () => import("/opt/build/repo/src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

